import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A Conversation Designer on the `}<a parentName="p" {...{
        "href": "https://assistant.google.com/"
      }}>{`Google Assistant`}</a>{`, Kimberly Harvey spent got her start as a UX researcher at Google. During that time, she conducted discovery and also iterative and evaluative research for Google Assistant and other Google products in both multimodal (voice and touch) and voice-only environments. This spring, you can find Kimberly at the SuperBot conference this spring. Learn more about her here.`}</p>
    <h3>{`What interests you about the voice assistant space?`}</h3>
    <p>{`I’m fascinated by the preexisting assumptions users have about voice assistants. As our technology gets better, how can we clearly align users expectations with capabilities we have now, and what we expect to have in the future.`}</p>
    <h3>{`How did you become a conversational designer?`}</h3>
    <p>{`As a UX researcher, I was fascinated while observing voice interactions. It seemed opposite to the environment we tried to create in a research session. While a researcher asks questions to help let a user guide a session, a Conversation designer creates a direct path for that user, with the illusion that he or she is the one in control.`}</p>
    <h3>{`What are some key tips you would give someone building a voice skill?`}</h3>
    <p>{`Conversation design is very linear, so I urge teams to think about the context around the conversation and how that might impact responses. Don’t forget to test!`}</p>
    <h3>{`Are there any interesting/unusual/unexpected outcomes you have seen during the design process?`}</h3>
    <p>{`The unusual thing about becoming a conversation designer, is that the process itself was exactly the same. Just like the traditional discipline, the better the design is, the invisible it seems.`}</p>
    <h3>{`What are you looking forward to at SuperBot?`}</h3>
    <p>{`I’m excited to connect more closely to the community and talk about the challenges of discoverability.`}</p>
    <hr></hr>
    <p><em parentName="p">{`Kimberly will be giving a talk at SuperBot 2020 in San Francisco this spring. To catch her talk and chat with her in person, register today for your tickets.`}</em></p>
    <div className="wp-block-button aligncenter is-style-squared">
  [Register for SuperBot](https://www.dashbot.io/superbot/sb2020)
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      